@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700");
@keyframes rotate {
  from {
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
.bg-primary {
  background-color: #3c1758 !important;
}

.bg-secondary {
  background-color: #091A3D !important;
}

.bg-success {
  background-color: #2EB67D !important;
}

.bg-info {
  background-color: #167895 !important;
}

.bg-warning {
  background-color: #EBB12B !important;
}

.bg-danger {
  background-color: #EB616A !important;
}

.bg-light {
  background-color: #F7F7F7 !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.bg-neutral {
  background-color: #FFFFFF !important;
}

.bg-dark {
  background-color: #1D1C1D !important;
}

.filter {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #212529;
  opacity: 0.45;
}
.filter.filter-primary {
  background-color: #3d1343;
}
.filter.filter-secondary {
  background-color: #030e2d;
}
.filter.filter-success {
  background-color: #28bc7e;
}
.filter.filter-info {
  background-color: #30a5ff;
}
.filter.filter-warning {
  background-color: #f4a900;
}
.filter.filter-danger {
  background-color: #ff3a3a;
}
.filter.filter-white {
  background-color: #e6e6e6;
}
.filter.filter-neutral {
  background-color: #e6e6e6;
}
.filter.filter-light {
  background-color: #d2d0d2;
}
.filter.filter-dark {
  background-color: #0a0c0d;
}

.btn,
.navbar .navbar-nav > a.btn {
  font-weight: 600;
  font-size: 0.9rem;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}
.btn:hover, .btn:focus,
.navbar .navbar-nav > a.btn:hover,
.navbar .navbar-nav > a.btn:focus {
  outline: 0 !important;
}
.btn:active, .btn.active, .open > .btn.dropdown-toggle,
.navbar .navbar-nav > a.btn:active,
.navbar .navbar-nav > a.btn.active,
.open > .navbar .navbar-nav > a.btn.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
}
.btn .badge,
.navbar .navbar-nav > a.btn .badge {
  margin: 0;
}
.btn.btn-link,
.navbar .navbar-nav > a.btn.btn-link {
  background-color: transparent;
}
.btn.btn-link:hover, .btn.btn-link:focus, .btn.btn-link:active, .btn.btn-link:active:focus,
.navbar .navbar-nav > a.btn.btn-link:hover,
.navbar .navbar-nav > a.btn.btn-link:focus,
.navbar .navbar-nav > a.btn.btn-link:active,
.navbar .navbar-nav > a.btn.btn-link:active:focus {
  background-color: transparent;
  text-decoration: none;
}
.btn.btn-lg,
.navbar .navbar-nav > a.btn.btn-lg {
  font-size: 1.25rem;
  border-radius: 0.3rem;
  padding: 0.5rem 1.2rem;
}
.btn.btn-sm,
.navbar .navbar-nav > a.btn.btn-sm {
  font-size: 0.875rem;
  border-radius: 0.2rem;
  padding: 0.25rem 0.7rem;
}
.btn.btn-pill,
.navbar .navbar-nav > a.btn.btn-pill {
  border-radius: 4rem;
}
.btn.btn-icon,
.navbar .navbar-nav > a.btn.btn-icon {
  padding: 0.5rem 0.8rem;
  border-radius: 4rem;
}
.btn.btn-icon.btn-sm,
.navbar .navbar-nav > a.btn.btn-icon.btn-sm {
  padding: 0.3rem 0.55rem;
}
.btn.btn-icon.btn-lg,
.navbar .navbar-nav > a.btn.btn-icon.btn-lg {
  padding: 0.65rem 1rem;
}

.btn-primary {
  background-color: #611F6A;
  border: 1px solid #611F6A;
}
.btn-primary:not(:disabled):hover, .btn-primary:not(:disabled):focus, .btn-primary:not(:disabled):active, .btn-primary:not(:disabled).active, .btn-primary:not(:disabled):active:focus, .btn-primary:not(:disabled):active:hover, .btn-primary:not(:disabled).active:focus, .btn-primary:not(:disabled).active:hover, .show > .btn-primary:not(:disabled).dropdown-toggle, .show > .btn-primary:not(:disabled).dropdown-toggle:focus, .show > .btn-primary:not(:disabled).dropdown-toggle:hover {
  background-color: #4b1852;
  border: 1px solid #4b1852;
  box-shadow: none;
}
.btn-primary:not([data-action]):hover {
  box-shadow: none;
}
.btn-primary.disabled, .btn-primary:disabled, .btn-primary[disabled],
.btn-primary fieldset[disabled] {
  background-color: #611F6A;
  border: 1px solid #611F6A;
}

.btn-secondary {
  background-color: #071D5C;
  border: 1px solid #071D5C;
}
.btn-secondary:not(:disabled):hover, .btn-secondary:not(:disabled):focus, .btn-secondary:not(:disabled):active, .btn-secondary:not(:disabled).active, .btn-secondary:not(:disabled):active:focus, .btn-secondary:not(:disabled):active:hover, .btn-secondary:not(:disabled).active:focus, .btn-secondary:not(:disabled).active:hover, .show > .btn-secondary:not(:disabled).dropdown-toggle, .show > .btn-secondary:not(:disabled).dropdown-toggle:focus, .show > .btn-secondary:not(:disabled).dropdown-toggle:hover {
  background-color: #051440;
  border: 1px solid #051440;
  box-shadow: none;
}
.btn-secondary:not([data-action]):hover {
  box-shadow: none;
}
.btn-secondary.disabled, .btn-secondary:disabled, .btn-secondary[disabled],
.btn-secondary fieldset[disabled] {
  background-color: #071D5C;
  border: 1px solid #071D5C;
}

.btn-success {
  background-color: #41d698;
  border: 1px solid #41d698;
}
.btn-success:not(:disabled):hover, .btn-success:not(:disabled):focus, .btn-success:not(:disabled):active, .btn-success:not(:disabled).active, .btn-success:not(:disabled):active:focus, .btn-success:not(:disabled):active:hover, .btn-success:not(:disabled).active:focus, .btn-success:not(:disabled).active:hover, .show > .btn-success:not(:disabled).dropdown-toggle, .show > .btn-success:not(:disabled).dropdown-toggle:focus, .show > .btn-success:not(:disabled).dropdown-toggle:hover {
  background-color: #2ccc8a;
  border: 1px solid #2ccc8a;
  box-shadow: none;
}
.btn-success:not([data-action]):hover {
  box-shadow: none;
}
.btn-success.disabled, .btn-success:disabled, .btn-success[disabled],
.btn-success fieldset[disabled] {
  background-color: #41d698;
  border: 1px solid #41d698;
}

.btn-info {
  background-color: #63bbff;
  border: 1px solid #63bbff;
}
.btn-info:not(:disabled):hover, .btn-info:not(:disabled):focus, .btn-info:not(:disabled):active, .btn-info:not(:disabled).active, .btn-info:not(:disabled):active:focus, .btn-info:not(:disabled):active:hover, .btn-info:not(:disabled).active:focus, .btn-info:not(:disabled).active:hover, .show > .btn-info:not(:disabled).dropdown-toggle, .show > .btn-info:not(:disabled).dropdown-toggle:focus, .show > .btn-info:not(:disabled).dropdown-toggle:hover {
  background-color: #44aeff;
  border: 1px solid #44aeff;
  box-shadow: none;
}
.btn-info:not([data-action]):hover {
  box-shadow: none;
}
.btn-info.disabled, .btn-info:disabled, .btn-info[disabled],
.btn-info fieldset[disabled] {
  background-color: #63bbff;
  border: 1px solid #63bbff;
}

.btn-warning {
  background-color: #ffbd28;
  border: 1px solid #ffbd28;
}
.btn-warning:not(:disabled):hover, .btn-warning:not(:disabled):focus, .btn-warning:not(:disabled):active, .btn-warning:not(:disabled).active, .btn-warning:not(:disabled):active:focus, .btn-warning:not(:disabled):active:hover, .btn-warning:not(:disabled).active:focus, .btn-warning:not(:disabled).active:hover, .show > .btn-warning:not(:disabled).dropdown-toggle, .show > .btn-warning:not(:disabled).dropdown-toggle:focus, .show > .btn-warning:not(:disabled).dropdown-toggle:hover {
  background-color: #ffb409;
  border: 1px solid #ffb409;
  box-shadow: none;
}
.btn-warning:not([data-action]):hover {
  box-shadow: none;
}
.btn-warning.disabled, .btn-warning:disabled, .btn-warning[disabled],
.btn-warning fieldset[disabled] {
  background-color: #ffbd28;
  border: 1px solid #ffbd28;
}

.btn-danger {
  background-color: #ff6d6d;
  border: 1px solid #ff6d6d;
}
.btn-danger:not(:disabled):hover, .btn-danger:not(:disabled):focus, .btn-danger:not(:disabled):active, .btn-danger:not(:disabled).active, .btn-danger:not(:disabled):active:focus, .btn-danger:not(:disabled):active:hover, .btn-danger:not(:disabled).active:focus, .btn-danger:not(:disabled).active:hover, .show > .btn-danger:not(:disabled).dropdown-toggle, .show > .btn-danger:not(:disabled).dropdown-toggle:focus, .show > .btn-danger:not(:disabled).dropdown-toggle:hover {
  background-color: #ff4e4e;
  border: 1px solid #ff4e4e;
  box-shadow: none;
}
.btn-danger:not([data-action]):hover {
  box-shadow: none;
}
.btn-danger.disabled, .btn-danger:disabled, .btn-danger[disabled],
.btn-danger fieldset[disabled] {
  background-color: #ff6d6d;
  border: 1px solid #ff6d6d;
}

.btn-white {
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
.btn-white:not(:disabled):hover, .btn-white:not(:disabled):focus, .btn-white:not(:disabled):active, .btn-white:not(:disabled).active, .btn-white:not(:disabled):active:focus, .btn-white:not(:disabled):active:hover, .btn-white:not(:disabled).active:focus, .btn-white:not(:disabled).active:hover, .show > .btn-white:not(:disabled).dropdown-toggle, .show > .btn-white:not(:disabled).dropdown-toggle:focus, .show > .btn-white:not(:disabled).dropdown-toggle:hover {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  box-shadow: none;
}
.btn-white:not([data-action]):hover {
  box-shadow: none;
}
.btn-white.disabled, .btn-white:disabled, .btn-white[disabled],
.btn-white fieldset[disabled] {
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
}

.btn-neutral {
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
.btn-neutral:not(:disabled):hover, .btn-neutral:not(:disabled):focus, .btn-neutral:not(:disabled):active, .btn-neutral:not(:disabled).active, .btn-neutral:not(:disabled):active:focus, .btn-neutral:not(:disabled):active:hover, .btn-neutral:not(:disabled).active:focus, .btn-neutral:not(:disabled).active:hover, .show > .btn-neutral:not(:disabled).dropdown-toggle, .show > .btn-neutral:not(:disabled).dropdown-toggle:focus, .show > .btn-neutral:not(:disabled).dropdown-toggle:hover {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  box-shadow: none;
}
.btn-neutral:not([data-action]):hover {
  box-shadow: none;
}
.btn-neutral.disabled, .btn-neutral:disabled, .btn-neutral[disabled],
.btn-neutral fieldset[disabled] {
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
}

.btn-light {
  background-color: #EBEAEB;
  border: 1px solid #EBEAEB;
}
.btn-light:not(:disabled):hover, .btn-light:not(:disabled):focus, .btn-light:not(:disabled):active, .btn-light:not(:disabled).active, .btn-light:not(:disabled):active:focus, .btn-light:not(:disabled):active:hover, .btn-light:not(:disabled).active:focus, .btn-light:not(:disabled).active:hover, .show > .btn-light:not(:disabled).dropdown-toggle, .show > .btn-light:not(:disabled).dropdown-toggle:focus, .show > .btn-light:not(:disabled).dropdown-toggle:hover {
  background-color: #dcdadc;
  border: 1px solid #dcdadc;
  box-shadow: none;
}
.btn-light:not([data-action]):hover {
  box-shadow: none;
}
.btn-light.disabled, .btn-light:disabled, .btn-light[disabled],
.btn-light fieldset[disabled] {
  background-color: #EBEAEB;
  border: 1px solid #EBEAEB;
}

.btn-dark {
  background-color: #212529;
  border: 1px solid #212529;
}
.btn-dark:not(:disabled):hover, .btn-dark:not(:disabled):focus, .btn-dark:not(:disabled):active, .btn-dark:not(:disabled).active, .btn-dark:not(:disabled):active:focus, .btn-dark:not(:disabled):active:hover, .btn-dark:not(:disabled).active:focus, .btn-dark:not(:disabled).active:hover, .show > .btn-dark:not(:disabled).dropdown-toggle, .show > .btn-dark:not(:disabled).dropdown-toggle:focus, .show > .btn-dark:not(:disabled).dropdown-toggle:hover {
  background-color: #131618;
  border: 1px solid #131618;
  box-shadow: none;
}
.btn-dark:not([data-action]):hover {
  box-shadow: none;
}
.btn-dark.disabled, .btn-dark:disabled, .btn-dark[disabled],
.btn-dark fieldset[disabled] {
  background-color: #212529;
  border: 1px solid #212529;
}

.btn-outline-primary {
  border: 1px solid #611F6A;
  color: #611F6A;
  /*
  	&.btn-lg {
  		@include hover-padding(.4375rem, 1.05rem);	
  	}

  	&.btn-sm {
  		@include hover-padding(.1875rem, .6rem);	
  	}
  */
  /*
          &.btn-icon {
  					@include hover-padding(.4rem, .7rem);
  					&.btn-sm {
  						padding: .2rem .45rem ;
  					}
  					&.btn-lg {
  						padding: .5rem .9rem;
  					}
          }
  */
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary:active:focus, .btn-outline-primary:active:hover, .btn-outline-primary.active:focus, .btn-outline-primary.active:hover, .show > .btn-outline-primary.dropdown-toggle, .show > .btn-outline-primary.dropdown-toggle:focus, .show > .btn-outline-primary.dropdown-toggle:hover {
  background-color: #611F6A;
  border-color: #611F6A;
  /*
  	      border: 2px solid $color;
  	      box-shadow: none;
  */
  /*
  	      color: $color;
  	      padding: .4375rem .915rem;
  */
}

.btn-outline-secondary {
  border: 1px solid #071D5C;
  color: #071D5C;
  /*
  	&.btn-lg {
  		@include hover-padding(.4375rem, 1.05rem);	
  	}

  	&.btn-sm {
  		@include hover-padding(.1875rem, .6rem);	
  	}
  */
  /*
          &.btn-icon {
  					@include hover-padding(.4rem, .7rem);
  					&.btn-sm {
  						padding: .2rem .45rem ;
  					}
  					&.btn-lg {
  						padding: .5rem .9rem;
  					}
          }
  */
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary:active:focus, .btn-outline-secondary:active:hover, .btn-outline-secondary.active:focus, .btn-outline-secondary.active:hover, .show > .btn-outline-secondary.dropdown-toggle, .show > .btn-outline-secondary.dropdown-toggle:focus, .show > .btn-outline-secondary.dropdown-toggle:hover {
  background-color: #071D5C;
  border-color: #071D5C;
  /*
  	      border: 2px solid $color;
  	      box-shadow: none;
  */
  /*
  	      color: $color;
  	      padding: .4375rem .915rem;
  */
}

.btn-outline-success {
  border: 1px solid #41d698;
  color: #41d698;
  /*
  	&.btn-lg {
  		@include hover-padding(.4375rem, 1.05rem);	
  	}

  	&.btn-sm {
  		@include hover-padding(.1875rem, .6rem);	
  	}
  */
  /*
          &.btn-icon {
  					@include hover-padding(.4rem, .7rem);
  					&.btn-sm {
  						padding: .2rem .45rem ;
  					}
  					&.btn-lg {
  						padding: .5rem .9rem;
  					}
          }
  */
}
.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success:active:focus, .btn-outline-success:active:hover, .btn-outline-success.active:focus, .btn-outline-success.active:hover, .show > .btn-outline-success.dropdown-toggle, .show > .btn-outline-success.dropdown-toggle:focus, .show > .btn-outline-success.dropdown-toggle:hover {
  background-color: #41d698;
  border-color: #41d698;
  /*
  	      border: 2px solid $color;
  	      box-shadow: none;
  */
  /*
  	      color: $color;
  	      padding: .4375rem .915rem;
  */
}

.btn-outline-info {
  border: 1px solid #63bbff;
  color: #63bbff;
  /*
  	&.btn-lg {
  		@include hover-padding(.4375rem, 1.05rem);	
  	}

  	&.btn-sm {
  		@include hover-padding(.1875rem, .6rem);	
  	}
  */
  /*
          &.btn-icon {
  					@include hover-padding(.4rem, .7rem);
  					&.btn-sm {
  						padding: .2rem .45rem ;
  					}
  					&.btn-lg {
  						padding: .5rem .9rem;
  					}
          }
  */
}
.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info:active:focus, .btn-outline-info:active:hover, .btn-outline-info.active:focus, .btn-outline-info.active:hover, .show > .btn-outline-info.dropdown-toggle, .show > .btn-outline-info.dropdown-toggle:focus, .show > .btn-outline-info.dropdown-toggle:hover {
  background-color: #63bbff;
  border-color: #63bbff;
  /*
  	      border: 2px solid $color;
  	      box-shadow: none;
  */
  /*
  	      color: $color;
  	      padding: .4375rem .915rem;
  */
}

.btn-outline-warning {
  border: 1px solid #ffbd28;
  color: #ffbd28;
  /*
  	&.btn-lg {
  		@include hover-padding(.4375rem, 1.05rem);	
  	}

  	&.btn-sm {
  		@include hover-padding(.1875rem, .6rem);	
  	}
  */
  /*
          &.btn-icon {
  					@include hover-padding(.4rem, .7rem);
  					&.btn-sm {
  						padding: .2rem .45rem ;
  					}
  					&.btn-lg {
  						padding: .5rem .9rem;
  					}
          }
  */
}
.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning:active:focus, .btn-outline-warning:active:hover, .btn-outline-warning.active:focus, .btn-outline-warning.active:hover, .show > .btn-outline-warning.dropdown-toggle, .show > .btn-outline-warning.dropdown-toggle:focus, .show > .btn-outline-warning.dropdown-toggle:hover {
  background-color: #ffbd28;
  border-color: #ffbd28;
  /*
  	      border: 2px solid $color;
  	      box-shadow: none;
  */
  /*
  	      color: $color;
  	      padding: .4375rem .915rem;
  */
}

.btn-outline-danger {
  border: 1px solid #ff6d6d;
  color: #ff6d6d;
  /*
  	&.btn-lg {
  		@include hover-padding(.4375rem, 1.05rem);	
  	}

  	&.btn-sm {
  		@include hover-padding(.1875rem, .6rem);	
  	}
  */
  /*
          &.btn-icon {
  					@include hover-padding(.4rem, .7rem);
  					&.btn-sm {
  						padding: .2rem .45rem ;
  					}
  					&.btn-lg {
  						padding: .5rem .9rem;
  					}
          }
  */
}
.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger:active:focus, .btn-outline-danger:active:hover, .btn-outline-danger.active:focus, .btn-outline-danger.active:hover, .show > .btn-outline-danger.dropdown-toggle, .show > .btn-outline-danger.dropdown-toggle:focus, .show > .btn-outline-danger.dropdown-toggle:hover {
  background-color: #ff6d6d;
  border-color: #ff6d6d;
  /*
  	      border: 2px solid $color;
  	      box-shadow: none;
  */
  /*
  	      color: $color;
  	      padding: .4375rem .915rem;
  */
}

.btn-outline-white {
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  /*
  	&.btn-lg {
  		@include hover-padding(.4375rem, 1.05rem);	
  	}

  	&.btn-sm {
  		@include hover-padding(.1875rem, .6rem);	
  	}
  */
  /*
          &.btn-icon {
  					@include hover-padding(.4rem, .7rem);
  					&.btn-sm {
  						padding: .2rem .45rem ;
  					}
  					&.btn-lg {
  						padding: .5rem .9rem;
  					}
          }
  */
}
.btn-outline-white:hover, .btn-outline-white:focus, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white:active:focus, .btn-outline-white:active:hover, .btn-outline-white.active:focus, .btn-outline-white.active:hover, .show > .btn-outline-white.dropdown-toggle, .show > .btn-outline-white.dropdown-toggle:focus, .show > .btn-outline-white.dropdown-toggle:hover {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  /*
  	      border: 2px solid $color;
  	      box-shadow: none;
  */
  /*
  	      color: $color;
  	      padding: .4375rem .915rem;
  */
}

.btn-outline-neutral {
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  /*
  	&.btn-lg {
  		@include hover-padding(.4375rem, 1.05rem);	
  	}

  	&.btn-sm {
  		@include hover-padding(.1875rem, .6rem);	
  	}
  */
  /*
          &.btn-icon {
  					@include hover-padding(.4rem, .7rem);
  					&.btn-sm {
  						padding: .2rem .45rem ;
  					}
  					&.btn-lg {
  						padding: .5rem .9rem;
  					}
          }
  */
}
.btn-outline-neutral:hover, .btn-outline-neutral:focus, .btn-outline-neutral:active, .btn-outline-neutral.active, .btn-outline-neutral:active:focus, .btn-outline-neutral:active:hover, .btn-outline-neutral.active:focus, .btn-outline-neutral.active:hover, .show > .btn-outline-neutral.dropdown-toggle, .show > .btn-outline-neutral.dropdown-toggle:focus, .show > .btn-outline-neutral.dropdown-toggle:hover {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  /*
  	      border: 2px solid $color;
  	      box-shadow: none;
  */
  /*
  	      color: $color;
  	      padding: .4375rem .915rem;
  */
}

.btn-outline-light {
  border: 1px solid #EBEAEB;
  color: #EBEAEB;
  /*
  	&.btn-lg {
  		@include hover-padding(.4375rem, 1.05rem);	
  	}

  	&.btn-sm {
  		@include hover-padding(.1875rem, .6rem);	
  	}
  */
  /*
          &.btn-icon {
  					@include hover-padding(.4rem, .7rem);
  					&.btn-sm {
  						padding: .2rem .45rem ;
  					}
  					&.btn-lg {
  						padding: .5rem .9rem;
  					}
          }
  */
}
.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light:active:focus, .btn-outline-light:active:hover, .btn-outline-light.active:focus, .btn-outline-light.active:hover, .show > .btn-outline-light.dropdown-toggle, .show > .btn-outline-light.dropdown-toggle:focus, .show > .btn-outline-light.dropdown-toggle:hover {
  background-color: #EBEAEB;
  border-color: #EBEAEB;
  /*
  	      border: 2px solid $color;
  	      box-shadow: none;
  */
  /*
  	      color: $color;
  	      padding: .4375rem .915rem;
  */
}

.btn-outline-dark {
  border: 1px solid #212529;
  color: #212529;
  /*
  	&.btn-lg {
  		@include hover-padding(.4375rem, 1.05rem);	
  	}

  	&.btn-sm {
  		@include hover-padding(.1875rem, .6rem);	
  	}
  */
  /*
          &.btn-icon {
  					@include hover-padding(.4rem, .7rem);
  					&.btn-sm {
  						padding: .2rem .45rem ;
  					}
  					&.btn-lg {
  						padding: .5rem .9rem;
  					}
          }
  */
}
.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark:active:focus, .btn-outline-dark:active:hover, .btn-outline-dark.active:focus, .btn-outline-dark.active:hover, .show > .btn-outline-dark.dropdown-toggle, .show > .btn-outline-dark.dropdown-toggle:focus, .show > .btn-outline-dark.dropdown-toggle:hover {
  background-color: #212529;
  border-color: #212529;
  /*
  	      border: 2px solid $color;
  	      box-shadow: none;
  */
  /*
  	      color: $color;
  	      padding: .4375rem .915rem;
  */
}

a:not(.nav-link):not(.navbar-brand):not(.btn):not(.no-decoration):not(.page-link) {
  color: #63bbff;
  font-weight: 600;
  font-weight: 500;
  text-decoration: none;
}
a:not(.nav-link):not(.navbar-brand):not(.btn):not(.no-decoration):not(.page-link):hover {
  color: #63bbff;
}

a.text-primary {
  color: #611F6A;
}
a.text-primary:hover {
  color: #4b1852 !important;
}

.form-control,
.custom-select,
.custom-file {
  font-weight: 500;
  height: 2.375rem;
  border: 1px solid #787878;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 0.9rem;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:hover, .form-control:focus, .form-control:active,
.custom-select:hover,
.custom-select:focus,
.custom-select:active,
.custom-file:hover,
.custom-file:focus,
.custom-file:active {
  border: 1px solid #611F6A;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control.form-control-lg, .form-control.custom-select-lg,
.custom-select.form-control-lg,
.custom-select.custom-select-lg,
.custom-file.form-control-lg,
.custom-file.custom-select-lg {
  font-size: 0.9rem;
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  line-height: 2rem;
  height: 3rem;
}
.form-control.form-control-sm, .form-control.custom-select-sm,
.custom-select.form-control-sm,
.custom-select.custom-select-sm,
.custom-file.form-control-sm,
.custom-file.custom-select-sm {
  font-size: 0.9rem;
  border-radius: 0.25rem;
  padding: 0.7rem 1rem;
  height: 1.95rem;
}
.form-control:disabled,
.custom-select:disabled,
.custom-file:disabled {
  background-color: transparent;
  border: 1px solid #EBEAEB;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:disabled:hover, .form-control:disabled:focus, .form-control:disabled:active,
.custom-select:disabled:hover,
.custom-select:disabled:focus,
.custom-select:disabled:active,
.custom-file:disabled:hover,
.custom-file:disabled:focus,
.custom-file:disabled:active {
  border: 1px solid #EBEAEB;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control.is-valid,
.custom-select.is-valid,
.custom-file.is-valid {
  color: #41d698;
  background-image: none;
  border: 1px solid #28bc7e;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control.is-valid:hover, .form-control.is-valid:focus, .form-control.is-valid:active,
.custom-select.is-valid:hover,
.custom-select.is-valid:focus,
.custom-select.is-valid:active,
.custom-file.is-valid:hover,
.custom-file.is-valid:focus,
.custom-file.is-valid:active {
  border: 1px solid #41d698;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control.is-invalid,
.custom-select.is-invalid,
.custom-file.is-invalid {
  color: #ff6d6d;
  background-image: none;
  border: 1px solid #ff3a3a;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control.is-invalid:hover, .form-control.is-invalid:focus, .form-control.is-invalid:active,
.custom-select.is-invalid:hover,
.custom-select.is-invalid:focus,
.custom-select.is-invalid:active,
.custom-file.is-invalid:hover,
.custom-file.is-invalid:focus,
.custom-file.is-invalid:active {
  border: 1px solid #ff6d6d;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group label {
  font-weight: 600;
  margin-top: 0.5rem;
}

.valid-feedback {
  color: #41d698;
}

.invalid-feedback {
  color: #ff6d6d;
}

.input-group .form-control:not(:first-child) {
  padding-left: 3rem;
  border-radius: 0.25rem;
}
.input-group .form-control:not(:last-child) {
  padding-right: 3rem;
  border-radius: 0.25rem;
}
.input-group .input-group-prepend,
.input-group .input-group-append {
  position: absolute;
  top: 0;
  z-index: 1000;
  border: 0;
}
.input-group .input-group-prepend {
  left: 0;
}
.input-group .input-group-append {
  right: 0;
}
.input-group .input-group-text {
  background-color: transparent;
  color: rgba(97, 31, 106, 0.9);
  border: none;
  padding: 0.7rem 0.8rem;
}
.input-group .input-group-text.disabled {
  color: rgba(97, 31, 106, 0.6);
}
.input-group .custom-file-label {
  border: 0;
}
.input-group .custom-file-label::after {
  background-color: transparent;
  color: #611F6A;
}

body {
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  color: #282828;
}

b {
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  color: #212529;
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  font-weight: 800;
}

.text-primary {
  color: #611F6A !important;
}

.text-secondary {
  color: #071D5C !important;
}

.text-success {
  color: #41d698 !important;
}

.text-info {
  color: #63bbff !important;
}

.text-warning {
  color: #ffbd28 !important;
}

.text-danger {
  color: #ff6d6d !important;
}

.text-white {
  color: #FFFFFF !important;
}

.text-neutral {
  color: #FFFFFF !important;
}

.text-light {
  color: #EBEAEB !important;
}

.text-dark {
  color: #212529 !important;
}

.blockquote {
  border-left: 0.2rem solid #611F6A;
  font-size: 1.1rem;
  padding-left: 1.5rem;
}

.icon {
  position: relative;
  top: 0.2em;
}

.big-icon {
  font-size: 3rem;
}

.noUi-target {
  background: #eceeef;
  border-radius: 5px;
  border: 0;
  box-shadow: inset 0 1px 2px rgba(90, 97, 105, 0.1);
  margin: 15px 0;
  cursor: pointer;
}

.noUi-horizontal {
  height: 5px;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -10px;
}

.noUi-vertical {
  width: 5px;
}

.noUi-connect {
  background: #611F6A;
  box-shadow: none;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  top: -5px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: none;
  cursor: pointer;
  background-color: #611F6A;
  border: 0;
  transition: box-shadow 0.15s, transform 0.15s;
}

.noUi-horizontal .noUi-handle.noUi-active,
.noUi-vertical .noUi-handle.noUi-active {
  transform: scale(1.2);
}

[disabled] .noUi-handle,
[disabled].noUi-origin {
  cursor: not-allowed;
}

/* Range slider value labels */
.range-slider-value {
  font-size: 0.75rem;
  font-weight: 500;
  background-color: transparent;
  color: #611F6A;
  border: 1px solid rgba(97, 31, 106, 0.4);
  border-radius: 1rem;
  padding: 0.4em 0.8em 0.3em 0.85em;
}

.range-slider-wrapper .upper-info {
  font-weight: 400;
  margin-bottom: 5px;
}

.input-slider-value-output {
  background: #333;
  color: #fff;
  padding: 4px 8px;
  position: relative;
  top: 12px;
  font-size: 11px;
  border-radius: 2px;
}

.input-slider-value-output:after {
  bottom: 100%;
  left: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #333;
  border-width: 4px;
  margin-left: -4px;
}

.input-slider-value-output.left:after {
  left: 10px;
  right: auto;
}

.input-slider-value-output.right:after {
  right: 10px;
  left: auto;
}

.noUi-handle:before, .noUi-handle:after {
  display: none;
}

.custom-control-label {
  cursor: pointer;
  font-size: 0.9rem;
  padding-top: 0.2rem;
  padding-left: 0.2rem;
}
.custom-control-label::before, .custom-control-label::after {
  width: 1.2rem;
  height: 1.2rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #611F6A;
  border-color: #611F6A;
  color: #611F6A;
}
.custom-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: rgba(97, 31, 106, 0.45);
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(97, 31, 106, 0.45);
  border-color: rgba(97, 31, 106, 0.45);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #611F6A;
  border-color: #611F6A;
}
.custom-radio .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: rgba(97, 31, 106, 0.45);
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(97, 31, 106, 0.45);
  border-color: rgba(97, 31, 106, 0.45);
}

.custom-switch .custom-control-label {
  padding-left: 1.3rem;
}
.custom-switch .custom-control-label::before {
  width: 3rem;
  height: 1.5rem;
  border-radius: 1rem;
}
.custom-switch .custom-control-label::after {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #611F6A;
  border-color: #611F6A;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.5rem);
}
.custom-switch .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: rgba(97, 31, 106, 0.45);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(97, 31, 106, 0.45);
  border-color: rgba(97, 31, 106, 0.45);
}

/*
.custom-toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 1.5rem;

    input {
        display: none;

        &:checked {
             + .custom-toggle-slider {
                border: 1px solid $primary;

                &:before {
                    background: $grey-200;
                    transform: translateX(1.625rem);
                }  
            }
        }

        &:disabled {
            + .custom-toggle-slider {
                border: 1px solid $primary;
            }

            &:checked {
                + .custom-toggle-slider {
                    border: lighten($primary, 45%);

                    &:before {
                        background-color: lighten($primary, 45%);
                    }
                }
            }
        }
    }
}
*/
/*
.custom-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: $custom-control-indicator-border-width solid $input-border-color;
    border-radius: 34px !important;
    background-color: transparent;


    &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 2px;
        bottom: 2px;
        border-radius: 50% !important;
        background-color: $custom-toggle-slider-bg;
        transition: $input-transition;
    }
}
*/
.navbar {
  padding: 1.2rem 1rem;
}
.navbar .navbar-brand {
  font-weight: 800;
}
.navbar .nav-link {
  font-weight: 500;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:active, .navbar-dark .navbar-nav .nav-link:focus {
  color: white;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(33, 37, 41, 0.9);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:active, .navbar-light .navbar-nav .nav-link:focus {
  color: #212529;
}

.navbar-transparent {
  background-color: transparent;
}

.table-primary tbody,
.table-primary tr,
.table-primary th,
.table-primary td {
  background-color: rgba(60, 23, 88, 0.3);
  border-color: rgba(60, 23, 88, 0.3);
  color: black;
}

.table-secondary tbody,
.table-secondary tr,
.table-secondary th,
.table-secondary td {
  background-color: rgba(9, 26, 61, 0.3);
  border-color: rgba(9, 26, 61, 0.3);
  color: black;
}

.table-success tbody,
.table-success tr,
.table-success th,
.table-success td {
  background-color: rgba(46, 182, 125, 0.3);
  border-color: rgba(46, 182, 125, 0.3);
  color: #05130d;
}

.table-info tbody,
.table-info tr,
.table-info th,
.table-info td {
  background-color: rgba(22, 120, 149, 0.3);
  border-color: rgba(22, 120, 149, 0.3);
  color: black;
}

.table-warning tbody,
.table-warning tr,
.table-warning th,
.table-warning td {
  background-color: rgba(235, 177, 43, 0.3);
  border-color: rgba(235, 177, 43, 0.3);
  color: #443106;
}

.table-danger tbody,
.table-danger tr,
.table-danger th,
.table-danger td {
  background-color: rgba(235, 97, 106, 0.3);
  border-color: rgba(235, 97, 106, 0.3);
  color: #720e15;
}

.table-light tbody,
.table-light tr,
.table-light th,
.table-light td {
  background-color: rgba(247, 247, 247, 0.3);
  border-color: rgba(247, 247, 247, 0.3);
  color: #919191;
}

.table-white tbody,
.table-white tr,
.table-white th,
.table-white td {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
  color: #999999;
}

.table-neutral tbody,
.table-neutral tr,
.table-neutral th,
.table-neutral td {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
  color: #999999;
}

.table-dark tbody,
.table-dark tr,
.table-dark th,
.table-dark td {
  background-color: rgba(29, 28, 29, 0.3);
  border-color: rgba(29, 28, 29, 0.3);
  color: black;
}

.table-hover .table-primary:hover th, .table-hover .table-primary:hover td {
  background-color: rgba(60, 23, 88, 0.35);
  border-color: rgba(60, 23, 88, 0.35);
}
.table-hover .table-secondary:hover th, .table-hover .table-secondary:hover td {
  background-color: rgba(9, 26, 61, 0.35);
  border-color: rgba(9, 26, 61, 0.35);
}
.table-hover .table-success:hover th, .table-hover .table-success:hover td {
  background-color: rgba(46, 182, 125, 0.35);
  border-color: rgba(46, 182, 125, 0.35);
}
.table-hover .table-info:hover th, .table-hover .table-info:hover td {
  background-color: rgba(22, 120, 149, 0.35);
  border-color: rgba(22, 120, 149, 0.35);
}
.table-hover .table-warning:hover th, .table-hover .table-warning:hover td {
  background-color: rgba(235, 177, 43, 0.35);
  border-color: rgba(235, 177, 43, 0.35);
}
.table-hover .table-danger:hover th, .table-hover .table-danger:hover td {
  background-color: rgba(235, 97, 106, 0.35);
  border-color: rgba(235, 97, 106, 0.35);
}
.table-hover .table-light:hover th, .table-hover .table-light:hover td {
  background-color: rgba(247, 247, 247, 0.35);
  border-color: rgba(247, 247, 247, 0.35);
}
.table-hover .table-white:hover th, .table-hover .table-white:hover td {
  background-color: rgba(255, 255, 255, 0.35);
  border-color: rgba(255, 255, 255, 0.35);
}
.table-hover .table-neutral:hover th, .table-hover .table-neutral:hover td {
  background-color: rgba(255, 255, 255, 0.35);
  border-color: rgba(255, 255, 255, 0.35);
}
.table-hover .table-dark:hover th, .table-hover .table-dark:hover td {
  background-color: rgba(29, 28, 29, 0.35);
  border-color: rgba(29, 28, 29, 0.35);
}

.table-dark td,
.table-dark th,
.table-secondary td,
.table-secondary th {
  color: #FFFFFF;
}

.nav.nav-tabs {
  display: inline-flex;
  margin-bottom: 1.5rem;
}
.nav.nav-tabs .nav-item:not(:last-child) {
  padding-right: 0.5rem;
}
.nav.nav-tabs .nav-item .nav-link {
  color: #686868;
  padding: 0.7rem 1.3rem;
  font-size: 1.1rem;
}
.nav.nav-tabs .nav-item .nav-link, .nav.nav-tabs .nav-item .nav-link.active, .nav.nav-tabs .nav-item .nav-link:hover, .nav.nav-tabs .nav-item .nav-link:focus {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.nav.nav-tabs .nav-item .nav-link.active, .nav.nav-tabs .nav-item .nav-link.active:hover {
  border-bottom: 2px solid #611F6A;
  border-color: #611F6A;
  font-weight: bold;
  color: #611F6A;
}
.nav.nav-tabs .nav-item .nav-link.active i {
  color: #611F6A;
}
.nav.nav-tabs .nav-item .nav-link:hover {
  border-bottom: 1px solid #611F6A;
  color: #611F6A;
}
.nav.nav-tabs .nav-item .nav-link:hover i {
  color: #611F6A;
}
.nav.nav-tabs .nav-item .nav-link.disabled {
  color: #909090;
}
.nav.nav-tabs .nav-item .nav-link.disabled i {
  color: #C0C0C0;
}
.nav.nav-tabs .nav-item .nav-link i {
  color: #909090;
}
.nav.nav-tabs.nav-primary .nav-item .nav-link.active, .nav.nav-tabs.nav-primary .nav-item .nav-link.active:hover {
  border-bottom: 2px solid #611F6A;
  border-color: #611F6A;
  font-weight: bold;
  color: #611F6A;
}
.nav.nav-tabs.nav-primary .nav-item .nav-link.active i {
  color: #611F6A;
}
.nav.nav-tabs.nav-primary .nav-item .nav-link:hover {
  border-bottom: 1px solid #611F6A;
  color: #611F6A;
}
.nav.nav-tabs.nav-primary .nav-item .nav-link:hover i {
  color: #611F6A;
}
.nav.nav-tabs.nav-secondary .nav-item .nav-link.active, .nav.nav-tabs.nav-secondary .nav-item .nav-link.active:hover {
  border-bottom: 2px solid #071D5C;
  border-color: #071D5C;
  font-weight: bold;
  color: #071D5C;
}
.nav.nav-tabs.nav-secondary .nav-item .nav-link.active i {
  color: #071D5C;
}
.nav.nav-tabs.nav-secondary .nav-item .nav-link:hover {
  border-bottom: 1px solid #071D5C;
  color: #071D5C;
}
.nav.nav-tabs.nav-secondary .nav-item .nav-link:hover i {
  color: #071D5C;
}
.nav.nav-tabs.nav-success .nav-item .nav-link.active, .nav.nav-tabs.nav-success .nav-item .nav-link.active:hover {
  border-bottom: 2px solid #41d698;
  border-color: #41d698;
  font-weight: bold;
  color: #41d698;
}
.nav.nav-tabs.nav-success .nav-item .nav-link.active i {
  color: #41d698;
}
.nav.nav-tabs.nav-success .nav-item .nav-link:hover {
  border-bottom: 1px solid #41d698;
  color: #41d698;
}
.nav.nav-tabs.nav-success .nav-item .nav-link:hover i {
  color: #41d698;
}
.nav.nav-tabs.nav-info .nav-item .nav-link.active, .nav.nav-tabs.nav-info .nav-item .nav-link.active:hover {
  border-bottom: 2px solid #63bbff;
  border-color: #63bbff;
  font-weight: bold;
  color: #63bbff;
}
.nav.nav-tabs.nav-info .nav-item .nav-link.active i {
  color: #63bbff;
}
.nav.nav-tabs.nav-info .nav-item .nav-link:hover {
  border-bottom: 1px solid #63bbff;
  color: #63bbff;
}
.nav.nav-tabs.nav-info .nav-item .nav-link:hover i {
  color: #63bbff;
}
.nav.nav-tabs.nav-warning .nav-item .nav-link.active, .nav.nav-tabs.nav-warning .nav-item .nav-link.active:hover {
  border-bottom: 2px solid #ffbd28;
  border-color: #ffbd28;
  font-weight: bold;
  color: #ffbd28;
}
.nav.nav-tabs.nav-warning .nav-item .nav-link.active i {
  color: #ffbd28;
}
.nav.nav-tabs.nav-warning .nav-item .nav-link:hover {
  border-bottom: 1px solid #ffbd28;
  color: #ffbd28;
}
.nav.nav-tabs.nav-warning .nav-item .nav-link:hover i {
  color: #ffbd28;
}
.nav.nav-tabs.nav-danger .nav-item .nav-link.active, .nav.nav-tabs.nav-danger .nav-item .nav-link.active:hover {
  border-bottom: 2px solid #ff6d6d;
  border-color: #ff6d6d;
  font-weight: bold;
  color: #ff6d6d;
}
.nav.nav-tabs.nav-danger .nav-item .nav-link.active i {
  color: #ff6d6d;
}
.nav.nav-tabs.nav-danger .nav-item .nav-link:hover {
  border-bottom: 1px solid #ff6d6d;
  color: #ff6d6d;
}
.nav.nav-tabs.nav-danger .nav-item .nav-link:hover i {
  color: #ff6d6d;
}
.nav.nav-tabs.nav-white .nav-item .nav-link.active, .nav.nav-tabs.nav-white .nav-item .nav-link.active:hover {
  border-bottom: 2px solid #FFFFFF;
  border-color: #FFFFFF;
  font-weight: bold;
  color: #FFFFFF;
}
.nav.nav-tabs.nav-white .nav-item .nav-link.active i {
  color: #FFFFFF;
}
.nav.nav-tabs.nav-white .nav-item .nav-link:hover {
  border-bottom: 1px solid #FFFFFF;
  color: #FFFFFF;
}
.nav.nav-tabs.nav-white .nav-item .nav-link:hover i {
  color: #FFFFFF;
}
.nav.nav-tabs.nav-neutral .nav-item .nav-link.active, .nav.nav-tabs.nav-neutral .nav-item .nav-link.active:hover {
  border-bottom: 2px solid #FFFFFF;
  border-color: #FFFFFF;
  font-weight: bold;
  color: #FFFFFF;
}
.nav.nav-tabs.nav-neutral .nav-item .nav-link.active i {
  color: #FFFFFF;
}
.nav.nav-tabs.nav-neutral .nav-item .nav-link:hover {
  border-bottom: 1px solid #FFFFFF;
  color: #FFFFFF;
}
.nav.nav-tabs.nav-neutral .nav-item .nav-link:hover i {
  color: #FFFFFF;
}
.nav.nav-tabs.nav-light .nav-item .nav-link.active, .nav.nav-tabs.nav-light .nav-item .nav-link.active:hover {
  border-bottom: 2px solid #EBEAEB;
  border-color: #EBEAEB;
  font-weight: bold;
  color: #EBEAEB;
}
.nav.nav-tabs.nav-light .nav-item .nav-link.active i {
  color: #EBEAEB;
}
.nav.nav-tabs.nav-light .nav-item .nav-link:hover {
  border-bottom: 1px solid #EBEAEB;
  color: #EBEAEB;
}
.nav.nav-tabs.nav-light .nav-item .nav-link:hover i {
  color: #EBEAEB;
}
.nav.nav-tabs.nav-dark .nav-item .nav-link.active, .nav.nav-tabs.nav-dark .nav-item .nav-link.active:hover {
  border-bottom: 2px solid #212529;
  border-color: #212529;
  font-weight: bold;
  color: #212529;
}
.nav.nav-tabs.nav-dark .nav-item .nav-link.active i {
  color: #212529;
}
.nav.nav-tabs.nav-dark .nav-item .nav-link:hover {
  border-bottom: 1px solid #212529;
  color: #212529;
}
.nav.nav-tabs.nav-dark .nav-item .nav-link:hover i {
  color: #212529;
}
.nav.nav-icons .nav-item .nav-link i {
  font-size: 1.5rem;
}
.nav.nav-pills {
  margin-bottom: 1.5rem;
}
.nav.nav-pills .nav-item .nav-link {
  color: #686868;
  padding: 0.4rem 1rem;
  font-size: 0.95rem;
}
.nav.nav-pills .nav-item .nav-link.active {
  background-color: #611F6A;
  box-shadow: 0px 3px 5px rgba(97, 31, 106, 0.2);
  color: #FFFFFF;
  font-weight: bold;
}
.nav.nav-pills .nav-item .nav-link.active:hover,
.nav.nav-pills .nav-item .nav-link.active:hover i, .nav.nav-pills .nav-item .nav-link.active:focus,
.nav.nav-pills .nav-item .nav-link.active:focus i {
  color: #FFFFFF;
}
.nav.nav-pills .nav-item .nav-link.disabled {
  color: #909090;
}
.nav.nav-pills .nav-item .nav-link.disabled i {
  color: #C0C0C0;
}
.nav.nav-pills .nav-item .nav-link:hover, .nav.nav-pills .nav-item .nav-link:focus {
  color: #611F6A;
}
.nav.nav-pills .nav-item .nav-link i {
  color: #909090;
}
.nav.nav-pills.nav-primary .nav-item .nav-link.active {
  background-color: #611F6A;
  box-shadow: 0px 3px 5px rgba(97, 31, 106, 0.2);
  color: #FFFFFF;
  font-weight: bold;
}
.nav.nav-pills.nav-primary .nav-item .nav-link.active:hover, .nav.nav-pills.nav-primary .nav-item .nav-link.active:focus,
.nav.nav-pills.nav-primary .nav-item .nav-link.active i {
  color: #FFFFFF !important;
}
.nav.nav-pills.nav-primary .nav-item .nav-link:hover, .nav.nav-pills.nav-primary .nav-item .nav-link:focus {
  color: #611F6A;
}
.nav.nav-pills.nav-primary .nav-item .nav-link:hover i, .nav.nav-pills.nav-primary .nav-item .nav-link:focus i {
  color: #611F6A;
}
.nav.nav-pills.nav-secondary .nav-item .nav-link.active {
  background-color: #071D5C;
  box-shadow: 0px 3px 5px rgba(7, 29, 92, 0.2);
  color: #FFFFFF;
  font-weight: bold;
}
.nav.nav-pills.nav-secondary .nav-item .nav-link.active:hover, .nav.nav-pills.nav-secondary .nav-item .nav-link.active:focus,
.nav.nav-pills.nav-secondary .nav-item .nav-link.active i {
  color: #FFFFFF !important;
}
.nav.nav-pills.nav-secondary .nav-item .nav-link:hover, .nav.nav-pills.nav-secondary .nav-item .nav-link:focus {
  color: #071D5C;
}
.nav.nav-pills.nav-secondary .nav-item .nav-link:hover i, .nav.nav-pills.nav-secondary .nav-item .nav-link:focus i {
  color: #071D5C;
}
.nav.nav-pills.nav-success .nav-item .nav-link.active {
  background-color: #41d698;
  box-shadow: 0px 3px 5px rgba(65, 214, 152, 0.2);
  color: #FFFFFF;
  font-weight: bold;
}
.nav.nav-pills.nav-success .nav-item .nav-link.active:hover, .nav.nav-pills.nav-success .nav-item .nav-link.active:focus,
.nav.nav-pills.nav-success .nav-item .nav-link.active i {
  color: #FFFFFF !important;
}
.nav.nav-pills.nav-success .nav-item .nav-link:hover, .nav.nav-pills.nav-success .nav-item .nav-link:focus {
  color: #41d698;
}
.nav.nav-pills.nav-success .nav-item .nav-link:hover i, .nav.nav-pills.nav-success .nav-item .nav-link:focus i {
  color: #41d698;
}
.nav.nav-pills.nav-info .nav-item .nav-link.active {
  background-color: #63bbff;
  box-shadow: 0px 3px 5px rgba(99, 187, 255, 0.2);
  color: #FFFFFF;
  font-weight: bold;
}
.nav.nav-pills.nav-info .nav-item .nav-link.active:hover, .nav.nav-pills.nav-info .nav-item .nav-link.active:focus,
.nav.nav-pills.nav-info .nav-item .nav-link.active i {
  color: #FFFFFF !important;
}
.nav.nav-pills.nav-info .nav-item .nav-link:hover, .nav.nav-pills.nav-info .nav-item .nav-link:focus {
  color: #63bbff;
}
.nav.nav-pills.nav-info .nav-item .nav-link:hover i, .nav.nav-pills.nav-info .nav-item .nav-link:focus i {
  color: #63bbff;
}
.nav.nav-pills.nav-warning .nav-item .nav-link.active {
  background-color: #ffbd28;
  box-shadow: 0px 3px 5px rgba(255, 189, 40, 0.2);
  color: #FFFFFF;
  font-weight: bold;
}
.nav.nav-pills.nav-warning .nav-item .nav-link.active:hover, .nav.nav-pills.nav-warning .nav-item .nav-link.active:focus,
.nav.nav-pills.nav-warning .nav-item .nav-link.active i {
  color: #FFFFFF !important;
}
.nav.nav-pills.nav-warning .nav-item .nav-link:hover, .nav.nav-pills.nav-warning .nav-item .nav-link:focus {
  color: #ffbd28;
}
.nav.nav-pills.nav-warning .nav-item .nav-link:hover i, .nav.nav-pills.nav-warning .nav-item .nav-link:focus i {
  color: #ffbd28;
}
.nav.nav-pills.nav-danger .nav-item .nav-link.active {
  background-color: #ff6d6d;
  box-shadow: 0px 3px 5px rgba(255, 109, 109, 0.2);
  color: #FFFFFF;
  font-weight: bold;
}
.nav.nav-pills.nav-danger .nav-item .nav-link.active:hover, .nav.nav-pills.nav-danger .nav-item .nav-link.active:focus,
.nav.nav-pills.nav-danger .nav-item .nav-link.active i {
  color: #FFFFFF !important;
}
.nav.nav-pills.nav-danger .nav-item .nav-link:hover, .nav.nav-pills.nav-danger .nav-item .nav-link:focus {
  color: #ff6d6d;
}
.nav.nav-pills.nav-danger .nav-item .nav-link:hover i, .nav.nav-pills.nav-danger .nav-item .nav-link:focus i {
  color: #ff6d6d;
}
.nav.nav-pills.nav-white .nav-item .nav-link.active {
  background-color: #FFFFFF;
  box-shadow: 0px 3px 5px rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
  font-weight: bold;
}
.nav.nav-pills.nav-white .nav-item .nav-link.active:hover, .nav.nav-pills.nav-white .nav-item .nav-link.active:focus,
.nav.nav-pills.nav-white .nav-item .nav-link.active i {
  color: #FFFFFF !important;
}
.nav.nav-pills.nav-white .nav-item .nav-link:hover, .nav.nav-pills.nav-white .nav-item .nav-link:focus {
  color: #FFFFFF;
}
.nav.nav-pills.nav-white .nav-item .nav-link:hover i, .nav.nav-pills.nav-white .nav-item .nav-link:focus i {
  color: #FFFFFF;
}
.nav.nav-pills.nav-neutral .nav-item .nav-link.active {
  background-color: #FFFFFF;
  box-shadow: 0px 3px 5px rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
  font-weight: bold;
}
.nav.nav-pills.nav-neutral .nav-item .nav-link.active:hover, .nav.nav-pills.nav-neutral .nav-item .nav-link.active:focus,
.nav.nav-pills.nav-neutral .nav-item .nav-link.active i {
  color: #FFFFFF !important;
}
.nav.nav-pills.nav-neutral .nav-item .nav-link:hover, .nav.nav-pills.nav-neutral .nav-item .nav-link:focus {
  color: #FFFFFF;
}
.nav.nav-pills.nav-neutral .nav-item .nav-link:hover i, .nav.nav-pills.nav-neutral .nav-item .nav-link:focus i {
  color: #FFFFFF;
}
.nav.nav-pills.nav-light .nav-item .nav-link.active {
  background-color: #EBEAEB;
  box-shadow: 0px 3px 5px rgba(235, 234, 235, 0.2);
  color: #FFFFFF;
  font-weight: bold;
}
.nav.nav-pills.nav-light .nav-item .nav-link.active:hover, .nav.nav-pills.nav-light .nav-item .nav-link.active:focus,
.nav.nav-pills.nav-light .nav-item .nav-link.active i {
  color: #FFFFFF !important;
}
.nav.nav-pills.nav-light .nav-item .nav-link:hover, .nav.nav-pills.nav-light .nav-item .nav-link:focus {
  color: #EBEAEB;
}
.nav.nav-pills.nav-light .nav-item .nav-link:hover i, .nav.nav-pills.nav-light .nav-item .nav-link:focus i {
  color: #EBEAEB;
}
.nav.nav-pills.nav-dark .nav-item .nav-link.active {
  background-color: #212529;
  box-shadow: 0px 3px 5px rgba(33, 37, 41, 0.2);
  color: #FFFFFF;
  font-weight: bold;
}
.nav.nav-pills.nav-dark .nav-item .nav-link.active:hover, .nav.nav-pills.nav-dark .nav-item .nav-link.active:focus,
.nav.nav-pills.nav-dark .nav-item .nav-link.active i {
  color: #FFFFFF !important;
}
.nav.nav-pills.nav-dark .nav-item .nav-link:hover, .nav.nav-pills.nav-dark .nav-item .nav-link:focus {
  color: #212529;
}
.nav.nav-pills.nav-dark .nav-item .nav-link:hover i, .nav.nav-pills.nav-dark .nav-item .nav-link:focus i {
  color: #212529;
}
.nav.nav-pills.nav-icons .nav-item .nav-link {
  padding: 0.6rem 1rem;
}

.breadcrumb .breadcrumb-item a {
  color: #611F6A !important;
  border-bottom: 1px solid #611F6A !important;
}
.breadcrumb .breadcrumb-item a:hover, .breadcrumb .breadcrumb-item a:focus {
  border-bottom: 2px solid #611F6A !important;
}

.pagination .page-item a.page-link {
  border: none;
  border-bottom: 0px;
  color: #909090;
  padding: 0.5rem 0.9rem;
}
.pagination .page-item a.page-link:hover, .pagination .page-item a.page-link:focus {
  background-color: transparent;
  color: #611F6A;
  border-bottom: 0px;
  box-shadow: none;
}
.pagination .page-item.active a.page-link {
  background-color: #611F6A;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 5px rgba(97, 31, 106, 0.2);
  color: #FFFFFF;
}
.pagination.pagination-lg a.page-link {
  padding: 0.6rem 1.2rem;
}
.pagination.pagination-sm a.page-link {
  padding: 0.2rem 0.6rem;
}

.badge {
  border-radius: 0.5rem;
  padding: 0.2rem 0.4rem;
}
.badge.badge-primary {
  background-color: transparent;
  color: #611F6A;
  border: 1px solid rgba(97, 31, 106, 0.4);
}
.badge.badge-secondary {
  background-color: transparent;
  color: #071D5C;
  border: 1px solid rgba(7, 29, 92, 0.4);
}
.badge.badge-success {
  background-color: transparent;
  color: #41d698;
  border: 1px solid rgba(65, 214, 152, 0.4);
}
.badge.badge-info {
  background-color: transparent;
  color: #63bbff;
  border: 1px solid rgba(99, 187, 255, 0.4);
}
.badge.badge-warning {
  background-color: transparent;
  color: #ffbd28;
  border: 1px solid rgba(255, 189, 40, 0.4);
}
.badge.badge-danger {
  background-color: transparent;
  color: #ff6d6d;
  border: 1px solid rgba(255, 109, 109, 0.4);
}
.badge.badge-white {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.badge.badge-neutral {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.badge.badge-light {
  background-color: transparent;
  color: #EBEAEB;
  border: 1px solid rgba(235, 234, 235, 0.4);
}
.badge.badge-dark {
  background-color: transparent;
  color: #212529;
  border: 1px solid rgba(33, 37, 41, 0.4);
}
.badge.badge-pill.badge-primary {
  background-color: #611F6A;
  color: #FFFFFF;
}
.badge.badge-pill.badge-secondary {
  background-color: #071D5C;
  color: #FFFFFF;
}
.badge.badge-pill.badge-success {
  background-color: #41d698;
  color: #FFFFFF;
}
.badge.badge-pill.badge-info {
  background-color: #63bbff;
  color: #FFFFFF;
}
.badge.badge-pill.badge-warning {
  background-color: #ffbd28;
  color: #FFFFFF;
}
.badge.badge-pill.badge-danger {
  background-color: #ff6d6d;
  color: #FFFFFF;
}
.badge.badge-pill.badge-white {
  background-color: #FFFFFF;
  color: #FFFFFF;
}
.badge.badge-pill.badge-neutral {
  background-color: #FFFFFF;
  color: #FFFFFF;
}
.badge.badge-pill.badge-light {
  background-color: #EBEAEB;
  color: #FFFFFF;
}
.badge.badge-pill.badge-dark {
  background-color: #212529;
  color: #FFFFFF;
}
.badge.badge-pill.badge-light {
  color: #212529;
}

.progress {
  height: 0.375rem;
  margin-bottom: 1rem;
}
.progress .progress-bar {
  background-color: #3c1758;
}
.progress.progress-sm {
  height: 0.2rem;
}
.progress.progress-lg {
  height: 0.5rem;
}

.alert.alert-primary {
  background-color: #3c1758;
  color: #FFFFFF;
  border: none;
}
.alert.alert-secondary {
  background-color: #091A3D;
  color: #FFFFFF;
  border: none;
}
.alert.alert-success {
  background-color: #2EB67D;
  color: #FFFFFF;
  border: none;
}
.alert.alert-info {
  background-color: #167895;
  color: #FFFFFF;
  border: none;
}
.alert.alert-warning {
  background-color: #EBB12B;
  color: #FFFFFF;
  border: none;
}
.alert.alert-danger {
  background-color: #EB616A;
  color: #FFFFFF;
  border: none;
}
.alert.alert-light {
  background-color: #F7F7F7;
  color: #FFFFFF;
  border: none;
}
.alert.alert-white {
  background-color: #FFFFFF;
  color: #FFFFFF;
  border: none;
}
.alert.alert-neutral {
  background-color: #FFFFFF;
  color: #FFFFFF;
  border: none;
}
.alert.alert-dark {
  background-color: #1D1C1D;
  color: #FFFFFF;
  border: none;
}
.alert a {
  color: #FFFFFF !important;
  border-bottom: 1px solid #FFFFFF !important;
}
.alert a:hover, .alert a:focus, .alert a:active {
  border-bottom: 2px solid #FFFFFF !important;
}

.tooltip-inner {
  background-color: #3c1758;
  border-radius: 0.25rem;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #3c1758;
}

.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #3c1758;
}

.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #3c1758;
}

.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #3c1758;
}

.popover {
  background-color: #EBB12B;
  border: none;
}
.popover .popover-body {
  color: #FFFFFF;
}

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-top .arrow::after {
  border-top-color: #EBB12B;
}
.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::before {
  border-top-color: transparent;
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-bottom .arrow::after {
  border-bottom-color: #EBB12B;
}
.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::before {
  border-bottom-color: transparent;
}

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-left .arrow::after {
  border-left-color: #EBB12B;
}
.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::before {
  border-left-color: transparent;
}

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-right .arrow::after {
  border-right-color: #EBB12B;
}
.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::before {
  border-right-color: transparent;
}

.modal .modal-dialog .modal-content {
  border: none;
  border-radius: 0rem;
  padding: 1rem;
}
.modal .modal-dialog .modal-content .modal-header {
  border-bottom: none;
}
.modal .modal-dialog .modal-content .modal-footer {
  border-top: none;
}

.modal-backdrop {
  background-color: #070207;
}
.modal-backdrop.show {
  opacity: 0.8;
}

.card {
  border-radius: 0;
  border: none;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  transition: transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card .card-header,
.card .card-footer {
  background-color: transparent;
  border: none;
  padding: 1rem;
  border-radius: 0rem;
}
.card .card-body {
  padding: 1rem;
}
.card .card-img {
  border-radius: 0;
}
.card:not(.no-hover):hover {
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.19);
  transform: scale(1.03);
}

.dropdown-toggle:focus {
  box-shadow: 0 none;
}

.dropdown-menu {
  border: 1px solid #EBEAEB;
  padding: 0.8rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  transition: all 0.1s;
}
.dropdown-menu a.dropdown-item {
  border-bottom: none !important;
  border-radius: 0.2rem;
  color: #212529 !important;
  text-align: left;
  padding: 0.6rem;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
}
.dropdown-menu a.dropdown-item:hover, .dropdown-menu a.dropdown-item:focus, .dropdown-menu a.dropdown-item:active {
  color: #63bbff !important;
  background-color: #F5F5F5;
}

.circle {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: #611F6A;
}

.bubble, .bubble.bubble-bottom-right, .bubble.bubble-bottom-left, .bubble.bubble-top-right, .bubble.bubble-top-left {
  width: 1.2rem;
  height: 1.2rem;
  background-color: #611F6A;
}
.bubble, .bubble.bubble-bottom-left   {
  border-radius: 50% 50% 50% 0%;
}
.bubble.bubble-bottom-right {
  border-radius: 50% 50% 0% 50%;
}
.bubble.bubble-top-right {
  border-radius: 50% 0% 50% 50%;
}
.bubble.bubble-top-left {
  border-radius: 0% 50% 50% 50%;
}

.big-bubble, .big-bubble.bubble-bottom-right, .big-bubble.bubble-bottom-left, .big-bubble.bubble-top-right, .big-bubble.bubble-top-left {
  width: 5rem;
  height: 5rem;
  background-color: #611F6A;
}
.big-bubble, .big-bubble.bubble-bottom-left   {
  border-radius: 50% 50% 50% 0%;
}
.big-bubble.bubble-bottom-right {
  border-radius: 50% 50% 0% 50%;
}
.big-bubble.bubble-top-right {
  border-radius: 50% 0% 50% 50%;
}
.big-bubble.bubble-top-left {
  border-radius: 0% 50% 50% 50%;
}

.rotate-circle {
  position: absolute;
  animation: rotate 5s linear infinite;
}

.rotate-bubble {
  position: absolute;
  animation: rotate 10s linear infinite;
}

.footer-1 {
  padding: 2rem 0rem 1rem;
}
.footer-1 .footer-menu {
  margin-bottom: 0rem;
}
.footer-1 .footer-menu li {
  padding: 0rem 0.8rem;
}
.footer-1 .footer-menu li a {
  color: #611F6A !important;
  border-bottom: 0 !important;
}
.footer-1 .footer-menu li a:hover, .footer-1 .footer-menu li a:focus, .footer-1 .footer-menu li a:active {
  color: #3d1343 !important;
}
.footer-1 .copyright {
  padding-top: 0.5rem;
}
.footer-1 .copyright p {
  padding: 0.5rem;
}
.footer-1 .copyright p i {
  margin: 1rem 0.1rem;
}